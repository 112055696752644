<template>
  <withdraw-page
    ref="withdraw_page"
    :configs="configs"
    :showOtpBox="showOtpBox"
    :isUserIndo="isUserIndo"
  >
    <template slot="title">
      {{ $t('funds.withdrawals.title_page') }}
    </template>
    <template slot="deposit-withdraw-header">
      <div class="list-balance" v-if="showOtpBox">
        <div class="item-balance">
          <span class="f-left">{{ $t('funds.history.to_address') }}</span>
          <span class="f-right">
            {{ withdrawAddress?.blockchain_address }}
          </span>
        </div>
        <div class="item-balance">
          <span class="f-left">{{ $t('funds.inputted_amount') }}</span>
          <span class="f-right">
            {{
              (Number(amount) + Number(withdrawLimit.fee))
                | splitAmount(coinName)
                | toNumber
                | formatCurrencyAmount(selectedCoin.coin, '0')
            }}
            <span class="name_coin_dps">{{ selectedCoin.shortNameCurrency }}</span>
          </span>
        </div>
        <div class="item-balance">
          <span class="f-left">{{ $t('funds.fee') }}</span>
          <span class="f-right">
            {{ withdrawLimit.fee | toNumber | formatCurrencyAmount(selectedCoin.coin, '0') }}
            <span class="name_coin_dps">{{ selectedCoin.shortNameCurrency }}</span>
          </span>
        </div>
        <div class="item-balance" v-if="isUserIndo">
          <span class="f-left">{{ $t('instant_convert.tax') }}</span>
          <span class="f-right">
            {{ formatFeeTax(amountTax, coinName) }}
            <span class="name_coin_dps">{{ selectedCoin.shortNameCurrency }}</span>
          </span>
        </div>
        <div class="item-balance">
          <span class="f-left">{{ $t('funds.you_will_get') }}</span>
          <span class="f-right">
            {{
              amountWillGet | splitAmount(coinName) | toNumber | formatCurrencyAmount(coinName, '0')
            }}
            <span class="name_coin_dps">{{ selectedCoin.shortNameCurrency }}</span>
          </span>
        </div>
      </div>
    </template>
    <template slot="actions">
      <div class="withdraw-content" v-if="!showOtpBox">
        <div class="list-balance">
          <div class="content-item-balance" style="background-color: #e7f3fc">
            <div class="item-balance" style="color: #0a5485">
              <span class="f-left">{{
                $t('funds.withdrawals.available_balance_to_withdraw')
              }}</span>
              <span class="f-right" style="color: #0a5485">
                {{
                  balance.available_balance
                    | toNumber
                    | formatCurrencyAmount(selectedCoin.coin, '0')
                }}
                <span class="name_coin_dps">{{ selectedCoin.shortNameCurrency }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="form-notice-danger">
          <ul class="first-ul">
            <li>
              {{ $t('funds.withdrawals.important.text2') }}:
              {{ minimumWithdrawFee | formatCurrencyAmount(coinName, '0') }}
              {{ coinName | uppercase }}
            </li>
            <li>{{ $t('funds.withdrawals.important.text3') }}</li>
            <li v-if="coinName === 'usdt'">{{ $t('funds.withdrawals.important.text4') }}</li>
          </ul>
        </div>

        <div class="form-group form-address-box">
          <div class="enter-address">
            <label>
              <div>{{ coinName | uppercase }} {{ $t('funds.withdrawals.withdrawal_address') }}</div>
            </label>
            <div class="switch-address">
              <span class="text-enter">{{ $t('funds.withdrawals.select_saved_address') }}</span>
              <label class="switch">
                <input
                  v-model="enterAddressManually"
                  type="checkbox"
                  @click="toggleEnterAddressManually()"
                />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div v-if="enterAddressManually">
            <div v-if="withdrawAddress.blockchain_address" class="address-box-new selected">
              <div class="wallet-name-container">
                <span class="wallet-name">{{ withdrawAddress.wallet_name }}</span>
                <svg
                  class="checked"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_297_38269)">
                    <path
                      d="M5 12L10 17L20 7"
                      stroke="#108CDD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_297_38269">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <span class="wallet-address">{{ withdrawAddress.blockchain_address }}</span>
              <span class="wallet-address">{{ withdrawAddress.blockchain_sub_address }}</span>
              <span class="wallet-address" style="text-transform: uppercase">{{
                withdrawAddress.network
              }}</span>
            </div>
            <div v-else class="no-address">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <g clip-path="url(#clip0_332_55144)">
                  <path
                    d="M17.5 8V5C17.5 4.73478 17.3946 4.48043 17.2071 4.29289C17.0196 4.10536 16.7652 4 16.5 4H6.5C5.96957 4 5.46086 4.21071 5.08579 4.58579C4.71071 4.96086 4.5 5.46957 4.5 6M4.5 6C4.5 6.53043 4.71071 7.03914 5.08579 7.41421C5.46086 7.78929 5.96957 8 6.5 8H18.5C18.7652 8 19.0196 8.10536 19.2071 8.29289C19.3946 8.48043 19.5 8.73478 19.5 9V12M4.5 6V18C4.5 18.5304 4.71071 19.0391 5.08579 19.4142C5.46086 19.7893 5.96957 20 6.5 20H18.5C18.7652 20 19.0196 19.8946 19.2071 19.7071C19.3946 19.5196 19.5 19.2652 19.5 19V16"
                    stroke="#9F9EA3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.5 12V16H16.5C15.9696 16 15.4609 15.7893 15.0858 15.4142C14.7107 15.0391 14.5 14.5304 14.5 14C14.5 13.4696 14.7107 12.9609 15.0858 12.5858C15.4609 12.2107 15.9696 12 16.5 12H20.5Z"
                    stroke="#9F9EA3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_332_55144">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
              <span>{{ $t('funds.withdrawals.no_address_selected') }}</span>
            </div>
            <span
              class="invalid-feedback"
              v-show="!withdrawAddress.blockchain_address && isEmptyAddress"
            >
              {{ $t('funds.withdrawals.error_address') }}
            </span>
            <div class="form-group-btn-address">
              <button type="button" @click="showModalAddressFunc" class="contained">
                {{
                  withdrawAddress.blockchain_address
                    ? $t('funds.withdrawals.change_address')
                    : $t('funds.withdrawals.select_address')
                }}
              </button>
              <button type="button" @click="showModalNewAddressFunc" class="bordered">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_404_17976)">
                    <path
                      d="M8.25 3.33398V12.6673"
                      stroke="#010304"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.58337 8H12.9167"
                      stroke="#010304"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_404_17976">
                      <rect width="16" height="16" fill="white" transform="translate(0.25)" />
                    </clipPath>
                  </defs>
                </svg>
                {{ $t('funds.withdrawals.form_add_new_address') }}
              </button>
            </div>
          </div>
          <input-block-chain-address-new
            v-else
            ref="blockchain_address"
            v-model="withdrawAddress"
            :coin="coinName"
            :withdrawAddresses="withdrawAddresses"
            :enableWhiteList="enableWhiteList"
            @onIsNoMemo="onIsNoMemo"
            @onAddNewAddress="onAddNewAddress"
            @focus="resetErrors"
            @onChangeNetwork="changeSelectedNetwork"
          />
        </div>
        <div class="form-group amount">
          <label
            >{{ $t('funds.withdrawals.amount') }} <span>{{ coinName | uppercase }}</span></label
          >
          <span class="f-right">
            {{ withdrawalLimit24hTxt() }}
          </span>
          <input-amount
            ref="amount"
            v-model="amount"
            :coin="coinName"
            :withdrawLimit="withdrawLimit"
            :balance="balance"
            :withdrawDaily="withdrawDaily"
            @focus="resetErrors"
            :minimumWithdrawFee="minimumWithdrawFee"
          />
          <span class="f-left responsive">
            {{ withdrawalLimit24hTxt() }}
          </span>
        </div>

        <div v-if="!withdrawLimit.currency" style="margin-right: auto">
          <p style="color: #0a5485">{{ $t('funds.withdrawals.withdrawal_please_wait') }}</p>
        </div>

        <p class="form-group quantity-box">
          <span class="f-left">
            {{ $t('funds.withdrawals.transaction_fee') }}:
            <span class="txt-bold"
              >{{ withdrawLimit.fee | formatCurrencyAmount(coinName, '0') }}
              {{ selectedCoin.coinName }}</span
            >
          </span>
          <span class="f-left" v-if="isUserIndoAndKyc">
            {{ $t('instant_convert.tax') }}:
            <span class="txt-bold"
              >{{ formatFeeTax(amountTax, coinName) }} {{ selectedCoin.coinName }}</span
            >
          </span>
          <span class="f-right">
            {{ $t('funds.withdrawals.you_will_get') }}:
            <span class="txt-bold"
              >{{
                (amountWillGet > 0 ? amountWillGet : 0) ||
                0 | toNumber | formatCurrencyAmount(coinName, '0')
              }}
              {{ selectedCoin.coinName }}</span
            >
          </span>
        </p>
        <div class="form-group-btn">
          <template v-if="isSubmitting">
            <button>
              {{ $t('funds.withdrawals.loading') }}
            </button>
          </template>
          <template v-else>
            <button v-if="!showOtpBox" type="submit" @click="withdraw">
              {{ $t('funds.withdrawals.submit') }}
            </button>
          </template>
        </div>
      </div>

      <WithdrawOTP
        v-else
        :duration="60"
        @submit-otp="verifySmsOtp"
        @resend-otp="onResendOtp"
        @resend-otp-sms="onResendSms"
        :error="errors.first('otp_sms')"
        :resetErrors="resetErrors"
        :phone_no="phone_no"
        :token="coinName.toUpperCase()"
      />

      <div class="form-notice note">
        <p class="txt-bold">{{ $t('funds.withdrawals.tips.text1') }}</p>
        <ul class="second-ul">
          <!-- take out "Network: Ethereum Testnet"  -->
          <li
            v-if="selectedCoin.coinName !== 'usdt'"
            v-html="$t('funds.deposit.tips.text5', { coinNetwork: coinNetwork })"
          ></li>
          <li v-html="$t('funds.withdrawals.tips.text2')"></li>
          <li v-html="$t('funds.withdrawals.tips.text3')"></li>
        </ul>
        <div class="withdrawal__footer-wrapper">
          <div class="footer__desc">{{ $t('chainalysis_note') }}</div>
          <div class="footer__logo">
            <img src="/images/withdrawal-footer-logo.svg" alt="" draggable="false" />
          </div>
        </div>
      </div>
      <PopupModal :is-show="isShowWithdrawalSuccess">
        <div slot="body" class="withdrawal_modal_success">
          <img
            class="icon-close"
            src="/images/icon/close-circle.svg"
            alt="icon"
            @click="isShowWithdrawalSuccess = false"
          />
          <div class="content">
            <img src="/images/icon-withdrawl-success.png" alt="" />
            <span class="text_main">{{ $t('withdraw_crypto_modal.request') }}</span>
            <span class="text_sub">{{ $t('withdraw_crypto_modal.sub_request') }}</span>
          </div>
        </div>
      </PopupModal>
      <otp-modal
        class="otp-form"
        ref="otp_modal"
        :submit="createTransaction"
        :params="getParams()"
        :is-disable-button="isDisableButton"
        :contact_email="siteSetting.contact_email"
      />
      <WithdrawalCryptoModal
        :isShow="isShowWithdrawalModal"
        @close="handleCloseWithdrawalModal"
        @submitWithdrawal="formModalSubmit"
        :isUnderRequiredAmount="underRequiredAmount"
        :kyc="kyc"
        :recepient_blockchain_address="withdrawAddress.blockchain_address"
        :travelRuleCrypto="travelRuleCryptoUsd"
      />
      <ModalAddress
        :isShow="showModalAddress"
        @close="closeModalAddress"
        :withdrawAddresses="withdrawAddresses"
        @onSelect="onSelectedAddress"
        :withdrawAddressParent="withdrawAddress"
        :coin="coinName"
        @onSuccessAdd="getWithdrawalAddresses"
        :isFetchingAddress="isFetchingAddress"
        :showAddNewAddress="showAddNewAddress"
      />
    </template>
  </withdraw-page>
</template>

<script>
import rf from '@/request/RequestFactory';
import BigNumber from 'bignumber.js';
import BaseDepositWithdraw from './BaseDepositWithdraw';
import InputAmount from '@/components/shared_components/funds/common/InputAmount.vue';
import InputBlockChainAddress from '@/components/shared_components/funds/withdraw/InputBlockChainAddress';
import InputBlockChainAddressNew from '@/components/shared_components/funds/withdraw/InputBlockChainAddressNew';
import ModalAddress from '@/components/shared_components/funds/withdraw/ModalAddress';
import CountDown from '@/components/shared_components/funds/common/CountDown.vue';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import WithdrawalCryptoModal from '@/components/shared_components/funds/common/WithdrawalCryptoModal.vue';
import { mapState } from 'vuex';
import Utils from '@/common/Utils';
import PopupModal from '@/components/PopupModal.vue';
import { maskPhoneNumber } from '@/utils/masking-phone';

import ResendOtpVue from '@/components/ResendOtp.vue';
import BUSDValidator from 'neka-wallet-address-validator';
import WAValidator from 'wallet-validator-testnet';
import WithdrawOTP from '@/components/shared_components/funds/withdraw_usd/WithdrawOTP.vue';

export default {
  name: 'Withdrawals',
  components: {
    OtpModal,
    InputAmount,
    InputBlockChainAddress,
    InputBlockChainAddressNew,
    ModalAddress,
    WithdrawPage: BaseDepositWithdraw,
    CountDown,
    WithdrawalCryptoModal,
    PopupModal,
    ResendOtpVue,
    WithdrawOTP,
  },
  data() {
    return {
      isShowResendSms: false,
      phone_no: '',
      document: {},
      configs: {
        type: 'withdraw',
        is_show_title: true,
        msgEmptyData: this.$i18n.t('funds.withdrawals.no_data'),
      },
      amount: '',
      balance: {},
      selectedCoin: {},
      withdrawLimit: {},
      withdrawDaily: '0',
      withdrawAddress: {},
      securitySetting: {},
      withdrawAddresses: [],
      isSubmitting: false,
      enableWhiteList: false,
      isDisableButton: false,
      siteSetting: {},
      isNoMemo: false,
      isAddNewAddress: false,
      showOtpBox: false,
      otpSms: '',
      isCountdownEnd: false,
      isShowWithdrawalModal: false,
      isShowWithdrawalSuccess: false,
      kyc: {},
      isWithdrawAddressInternal: true,
      isDisabledWithdrawl: true,
      ratePrice: {},
      file_document: null,
      travelRuleSettings: [],
      finalOtp: [],
      isVerifying: false,
      enterAddressManually: false,
      showModalAddress: false,
      isEmptyAddress: false,
      temporarySelectedAddress: {},
      temporaryInputAddress: {},
      isFetchingAddress: {},
      showAddNewAddress: false,
      network: 'erc20',
      underRequiredAmount: true,
    };
  },
  computed: {
    ...mapState({
      taxSettings: (state) =>
        state.taxSettings.filter((item) => item.type_exchange.includes('withdrawal')),
      isDeviceHover: (state) => state.isDeviceHover,
    }),
    tax_rate() {
      return this.taxSettings.reduce((acc, item) => {
        return new BigNumber(acc).plus(item.tax_rate).toString();
      }, 0);
    },
    travelRuleCrypto() {
      // console.log(this.travelRuleSettings, '<<< TRCrypto');
      // const settings = this.travelRuleSettings.find(
      //   (item) => item.travel_rule_type === 'withdraw_crypto'
      // );
      const settings = this.travelRuleSettings.find(
        (item) => item.travel_rule_type === 'withdraw_idr'
      );
      return settings ? settings : {};
    },
    travelRuleCryptoUsd() {
      const settings = this.travelRuleSettings.find(
        (item) => item.travel_rule_type === 'withdraw_crypto'
      );
      return settings ? settings : {};
    },
    amountTax() {
      if (
        this.amount &&
        this.withdrawAddress.blockchain_address &&
        !this.isWithdrawAddressInternal
      ) {
        const num = new BigNumber(this.amount || 0)
          .plus(`${this.withdrawLimit.fee || 0}`)
          .mul(this.tax_rate);
        return num > 0 ? num : 0;
      }
      return 0;
    },
    amountWillGet() {
      if (this.kyc.country === 'Indonesia') {
        return new BigNumber(`${this.amount}` || 0).minus(this.amountTax).toString();
      }
      return new BigNumber(this.amount || 0).toString();
    },
    minimumWithdrawFee() {
      let minAmount = new BigNumber(`${this.withdrawLimit.minium_withdrawal || 0}`).plus(
        `${this.withdrawLimit.fee || 0}`
      );
      if (!this.isWithdrawAddressInternal && this.isUserIndo) {
        minAmount = minAmount.div(1 - this.tax_rate);
      }
      return minAmount.toString();
    },
    isUserIndo() {
      return true; //make all user taxed due to bussiness update

      // return this.kyc.country === 'Indonesia'
    },
    isUserIndoAndKyc() {
      return this.kyc.status === 'verified'; //make all user taxed due to bussiness update

      // return this.kyc.country === 'Indonesia' && this.kyc.status === 'verified'
    },
    isShowFormTravelRule() {
      return Number(this.travelRuleCrypto.limit_amount) > 0;
    },
    isUnderRequiredAmount() {
      const rate = new BigNumber(this.ratePrice.price || 0);
      const inputNum = new BigNumber(this.amount || 0).plus(this.withdrawLimit.fee || 0);
      const amountByRate = Number(inputNum.mul(rate).toString());
      return amountByRate <= Number(this.travelRuleCrypto.limit_amount);
    },
    coinName() {
      if (_.isEmpty(this.selectedCoin.coinName)) {
        return '';
      }
      return this.selectedCoin.coinName;
    },
    coinNetwork() {
      if (_.isEmpty(this.selectedCoin.coinNetwork)) {
        return '';
      }
      return this.selectedCoin.coinNetwork;
    },
    coinByCoinName() {
      if (this.coinName === 'ada') return 'binance-peg-cardano';
      if (this.coinName === 'busd') return 'binance-usd';
      if (this.coinName === 'btc') return 'bitcoin';
      if (this.coinName === 'eth') return 'ethereum';
      if (this.coinName === 'bch') return 'binance-peg-bitcoin-cash';
      if (this.coinName === 'ltc') return 'binance-peg-litecoin';
      if (this.coinName === 'xrp') return 'binance-peg-xrp';
      if (this.coinName === 'bnb') return 'heco-peg-bnb';
      if (this.coinName === 'idr') return 'rupiah-token';
      if (this.coinName === 'usdt') return 'tether';
      return 'usdt';
    },
  },
  watch: {
    'withdrawAddress.blockchain_address'() {
      if (!this.withdrawAddress.blockchain_address || !this.coinName) {
        this.isWithdrawAddressInternal = true;
        return;
      }
      this.checkAddressInternal();
    },
    'withdrawAddress.blockchain_sub_address'() {
      if (!this.withdrawAddress.blockchain_address || !this.coinName) {
        this.isWithdrawAddressInternal = true;
        return;
      }
      this.checkAddressInternal();
    },
    isShowWithdrawalModal(){
      this.isSubmitting = false
    },
    coinName() {
      this.convertAmount();
      this.getDepositAddress();
      if (!this.withdrawAddress.blockchain_address || !this.coinName) {
        this.isWithdrawAddressInternal = true;
        return;
      }
      this.checkAddressInternal();
    },
    network() {
      this.getDepositAddress();
    },

    finalOtp() {
      this.otpSms = this.finalOtp.join('');
    },
  },

  methods: {
    handleCountdownEnd() {
      this.isCountdownEnd = true;
      this.isShowResendSms = true;
    },
    maskPhoneNumberFunc() {
      return maskPhoneNumber(this.phone_no);
    },
    handleKeyDown(event, index) {
      if (event.key !== 'Tab' && event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
        event.preventDefault();
      }

      if (
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.which === 8 ||
        event.which === 46 ||
        !event.key ||
        !event.which
      ) {
        this.$set(this.finalOtp, index, null);

        if (index != 0) {
          this.$refs.otpCont?.children[index - 1].focus();
        }
        return;
      }
      if (new RegExp('^([0-9])$').test(event.key)) {
        this.$set(this.finalOtp, index, event.key);

        if (index != 5) {
          this.$refs.otpCont?.children[index + 1].focus();
        }
      }
    },
    withdrawalLimit24hTxt() {
      return (
        this.$t('funds.withdrawals.withdrawal_limit_24') +
        ' ' +
        Utils.formatCurrencyAmount(this.withdrawDaily, this.coinName, '0') +
        '/' +
        Utils.formatCurrencyAmount(this.withdrawLimit.daily_limit, this.coinName, '0') +
        this.coinName.toString().toUpperCase()
      );
    },
    handleOnCompleteOtp(otp) {
      this.otpSms = otp;
    },
    async getTravelRuleLimit() {
      await rf
        .getRequest('UserRequest')
        .getTravelRuleLimit()
        .then((res) => {
          this.travelRuleSettings = res.data;
        });
    },
    formatFeeTax(amount, currency) {
      if (
        amount != 0 &&
        new BigNumber(amount).lt(0.00000001) &&
        currency !== 'idr'
        // &&
        // currency !== 'usdt' &&
        // currency !== 'busd'
      )
        return '0.00000001';
      return Utils.formatCurrencyAmount(amount, currency, '0');
    },
    handleCloseWithdrawalModal() {
      this.isShowWithdrawalModal = false;
    },
    async onResendOtp() {
      let params = {
        type: 'withdraw',
        currency: this.coinName,
      };
      this.isCountdownEnd = false;
      await rf
        .getRequest('UserRequest')
        .resendWA(params)
        .then((res) => {
          if (res.success) {
            Message.success(this.$i18n.t('funds.withdrawals.resend-sms-success_message'));
          }
        });
      this.setCountDown();
    },
    async onResendSms() {
      let params = {
        type: 'withdraw',
        currency: this.coinName,
      };
      await rf
        .getRequest('UserRequest')
        .resendSMS(params)
        .then((res) => {
          if (res.success) {
            Message.success(this.$i18n.t('funds.withdrawals.resend-sms-success_message'));
          }
        });
      this.setCountDown();
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    onIsNoMemo(isNoMemo) {
      this.isNoMemo = isNoMemo;
    },
    onAddNewAddress(val) {
      this.isAddNewAddress = val;
    },
    getSecuritySettings() {
      rf.getRequest('UserRequest')
        .getSecuritySettings()
        .then((res) => {
          this.securitySetting = res.data;
        });
    },

    getUserKyc() {
      rf.getRequest('UserRequest')
        .getUserKyc()
        .then((res) => {
          this.kyc = res.data || {};
        });
    },
    getDepositAddress() {
      rf.getRequest('UserRequest')
        .getDepositAddress({ currency: this.coinName })
        .then((res) => {
          if (this.network == 'trc20') {
            this.kyc.blockchain_address = res.data.blockchain_address_trc20;
          } else {
            this.kyc.blockchain_address = res.data.blockchain_address;
          }
        });
    },
    getSocketEventHandlers() {
      return {
        OtpUpdated: this.onOtpUpdated,
        selectedCoinChange: this.onSelectedCoinChange,
      };
    },

    onSelectedCoinChange() {
      this.showOtpBox = false;
      this.withdrawAddress = {};
      this.temporarySelectedAddress = {};
    },

    async validate() {
      this.errors.clear();
      if (new BigNumber(`${this.withdrawLimit.daily_limit || 0}`).comparedTo(0) === 0) {
        this.errors.add('amount', this.$i18n.t('funds.withdrawals.errors.not_allowed_withdraw'));
      }
      this.$refs.amount.validate();
      !this.enterAddressManually && this.$refs.blockchain_address.validate();
      if (this.enterAddressManually && !this.withdrawAddress.blockchain_address) {
        this.isEmptyAddress = true;
        return;
      }

      const res = await rf
        .getRequest('UserRequest')
        .validateBlockchainAddress(this.coinName, this.withdrawAddress.blockchain_address);

      if (!res.data) {
        this.errors.add('blockchain_address', this.$t('address.errors.blockchain_address'));
        return false;
      }

      try {
        let valid = false;
        if (this.coinName === 'busd') {
          valid = BUSDValidator.validate(
            this.withdrawAddress.blockchain_address,
            this.coinName,
            'both'
          );
        } else if (this.coinName === 'usdt' && this.network === 'trc20') {
          if (
            this.withdrawAddress.blockchain_address[0] === 'T' &&
            this.withdrawAddress.blockchain_address.length == 34
          ) {
            valid = true;
          }
        } else {
          valid = WAValidator.validate(
            this.withdrawAddress.blockchain_address,
            this.coinName,
            'both'
          );
        }

        console.log(valid);

        if (!valid) {
          this.errors.add('blockchain_address', this.$t('address.errors.blockchain_address'));
          return false;
        }
      } catch (error) {
        this.errors.add('blockchain_address', this.$t('address.errors.blockchain_address'));
        return false;
      }

      if (this.errors.count() > 0) {
        return false;
      }
      return true;
    },

    async resendWA() {
      let params = {
        type: 'withdraw',
        currency: this.coinName,
      };
      await rf
        .getRequest('UserRequest')
        .resendWA(params)
        .then((res) => {
          if (res.success) {
            this.showOtpBox = true;
            this.isSubmitting = false
          }
        });
      this.setCountDown();
    },
    async withdraw() {
      this.isSubmitting = true

      if (this.isDisabledWithdrawl){
        this.isSubmitting = false
        return;
      } 

      if (this.withdrawAddress.network) {
        this.network = this.withdrawAddress.network;
      }
      if (!(await this.validate())) {
        this.isSubmitting = false
        return false;
      }
      if (!this.securitySetting.otp_verified) {
        Message.error(
          this.$i18n.t('account.otp.should_enable_first'),
          {},
          { position: 'top_right' }
        );
        this.isSubmitting = false
        return;
      }

      if (this.kyc.status !== 'verified') {
        this.isSubmitting = false
        return Message.error(this.$t('not_complete_kyc'));
      }
      await this.getTravelRuleLimit();
      if (this.isShowFormTravelRule) {
        // disini bang
        // console.log(
        //   Number(this.amount),
        //   Number(this.withdrawLimit.fee),
        //   Number(this.travelRuleCrypto),
        //   Number(this.amount) + Number(this.withdrawLimit.fee) >
        //     Number(this.travelRuleCrypto?.limit_amount)
        // );
        let getCoinConversion = await rf
          .getRequest('PriceRequest')
          .getIDRConversionPrice(this.coinName);
        let IDRPrice = getCoinConversion.data[`idr_${this.coinName}`].current_price;
        let inputAmount = Number(this.amount) + Number(this.withdrawLimit.fee);
        let amountIDRConversion = IDRPrice * inputAmount;
        console.log(
          'current_price IDR :',
          IDRPrice,
          '||',
          'wd amount in IDR :',
          amountIDRConversion,
          '||',
          'Travel Rule :',
          Number(this.travelRuleCrypto?.limit_amount)
        );
        if (amountIDRConversion > Number(this.travelRuleCrypto?.limit_amount)) {
          this.isShowWithdrawalModal = true;
          this.underRequiredAmount = false;
        } else {
          this.underRequiredAmount = true;
          this.resendWA();
        }
        // this.isShowWithdrawalModal = true;
      } else {
        this.resendWA();
      }
    },
    formModalSubmit(document, file_document) {
      this.document = JSON.stringify(document);
      this.file_document = file_document ? file_document : null;
      this.isShowWithdrawalModal = false;
      this.resendWA();
    },

    onError(errors) {
      this.errors.clear();
      _.forEach(errors, (messages, field) => {
        _.forEach(messages, (message) => {
          this.errors.add(field, message);
        });
      });
    },
    resAmount(amount, currency) {
      const amountArr = amount.split('.');
      if (amountArr.length == 1) {
        return `-${amount}`;
      } else {
        let decimal = amountArr[1];
        switch (currency) {
          case 'xrp':
            decimal = decimal.slice(0, 6);
            break;
          default:
            decimal = decimal.slice(0, 8);
        }
        return `-${amountArr[0]}.${decimal}`;
      }
    },

    getParams() {
      let params = {
        currency: this.coinName,
        amount: this.resAmount(this.amount, this.coinName),
        blockchain_address: this.withdrawAddress.blockchain_address,
        is_no_memo: this.isNoMemo,
        otp_sms: this.otpSms,
        network: this.network,
      };
      if (this.isAddNewAddress) {
        (params.is_new_address = 1), (params.wallet_name = this.withdrawAddress.wallet_name);
      }
      
      params.document = this.document;

      if (this.coinName === 'xrp' || this.coinName === 'eos') {
        params.blockchain_sub_address = this.withdrawAddress.blockchain_sub_address;
      }
      return params;
    },
    getFormData(params) {
      console.log(params);
      if (this.isUnderRequiredAmount) return params;
      const formData = new FormData();
      formData.append('currency', params.currency);
      formData.append('amount', params.amount);
      formData.append('blockchain_address', params.blockchain_address);
      formData.append('is_no_memo', params.is_no_memo);
      if (params.is_new_address) {
        formData.append('is_new_address', params.is_new_address);
        formData.append('wallet_name', params.wallet_name);
      }
      formData.append('otp_sms', params.otp_sms);
      formData.append('otp', params.otp);
      if (this.coinName === 'xrp' || this.coinName === 'eos') {
        formData.append('blockchain_sub_address', params.blockchain_sub_address);
      }
      formData.append('document', params.document);
      if (this.file_document) {
        formData.append('file_document', this.file_document);
      }
      formData.append('network', params.network);
      return formData;
    },

    createTransaction(params) {
      this.isDisableButton = true;
      this.isSubmitting = true;
      if (!this.securitySetting.otp_verified) {
        this.resetErrors();
        this.isDisableButton = false;
        this.isSubmitting = false;
        CommonModal.hide('OtpModal');
        Message.error(this.$i18n.t('funds.withdrawals.errors.otp'), {}, { position: 'top_right' });
        return;
      }

      console.log('params doc', params.document);

      if (typeof params.document != 'string') {
        params.document = JSON.stringify({
          origin: {
            full_name: this.kyc.full_name,
            address: this.kyc.address,
            identification_number: this.kyc.id_number,
            wallet_address: this.kyc.blockchain_address,
            date_of_birth: this.kyc.date_of_birth,
          },
          recepient: {
            full_name: 'instant withdraw',
            address: 'instant withdraw',
            wallet_address: this.withdrawAddress.blockchain_address,
          },
        });
      }
      
      // const payload = this.isShowFormTravelRule ? this.getFormData(params) : params;
      rf.getRequest('TransactionRequest')
        .withdraw(params)
        .then((res) => {
          this.onCreateTransaction(res.data);
          this.$broadcast('TransactionCreated');
          this.isDisableButton = false;
          CommonModal.hide('OtpModal');
        })
        .catch((error) => {
          this.isDisableButton = false;
          this.isSubmitting = false;
          if (error.response.data.errors) {
            const errors = error.response.data.errors;
            Object.keys(errors).forEach((key) => {
              this.errors.add({
                field: key,
                msg: this.$t(errors[key][0]),
              });
            });
            if (!errors.has('otp')) {
              CommonModal.hide('OtpModal');
            }
          } else {
            CommonModal.hide('OtpModal');
            Message.error(this.$t(error.response.data.message), {}, { position: 'top_right' });
          }
        });
    },

    onCreateTransaction(data) {
      this.isSubmitting = false;
      this.withdrawAddress = {};
      this.amount = '';
      this.otpSms = '';
      this.finalOtp = [];
      this.showOtpBox = false;
      this.getWithdrawalAddresses();
      this.getWithdrawDaily(this.coinName);
      CommonModal.hide('OtpModal');
      // Message.success(this.$i18n.t('funds.withdrawals.success_message'))
      this.isShowWithdrawalSuccess = true;
    },
    convertAmount() {
      this.isDisabledWithdrawl = true;
      rf.getRequest('UserRequest')
        .getTravelRuleRatePrice(this.coinName)
        .then((res) => {
          this.ratePrice = res.data;
        })
        .finally(() => {
          this.isDisabledWithdrawl = false;
        });
    },
    getWithdrawalAddresses() {
      this.isFetchingAddress = true;
      rf.getRequest('UserRequest')
        .getWithdrawalAddresses({ coin: this.coinName })
        .then((res) => {
          this.withdrawAddresses = res.data;
          this.isFetchingAddress = false;
        });
    },

    getWithdrawDaily(coin) {
      rf.getRequest('UserRequest')
        .getWithdrawDaily(coin)
        .then((res) => {
          this.withdrawDaily = `${res.data || 0}`;
        });
    },
    checkAddressInternal() {
      rf.getRequest('UserRequest')
        .checkAddressInternal(
          this.coinName,
          this.withdrawAddress.blockchain_address,
          this.withdrawAddress.blockchain_sub_address
        )
        .then((res) => {
          this.isWithdrawAddressInternal = res.data;
        });
    },

    init() {
      this.errors.clear();
      this.getWithdrawalAddresses();
      this.getSecuritySettings();
      this.getSettingWithdrawLimit();
      this.getSettingEnableWhiteList();
      this.getMasterData();
    },

    getSettingEnableWhiteList() {
      rf.getRequest('UserRequest')
        .getUserSettings()
        .then((res) => {
          const row =
            _.find(res.data, (row) => {
              return row.key === 'whitelist';
            }) || {};
          this.enableWhiteList = !!parseInt(row.value);
        });
    },

    getSettingWithdrawLimit(useCache = true) {
      rf.getRequest('UserRequest')
        .getCurrentUser(useCache)
        .then((res) => {
          this.phone_no = res.data.phone_no;
          const securityLevel = res.data.security_level;
          let withdrawLimit = {};
          rf.getRequest('MasterdataRequest')
            .getAll()
            .then((res) => {
              withdrawLimit =
                window._.find(res.withdrawal_limits, (wl) => {
                  return wl.security_level === securityLevel && wl.currency === this.coinName;
                }) || {};
              this.withdrawLimit = withdrawLimit;
            });
        });
    },

    onOtpUpdated() {
      this.getSecuritySettings();
      this.getSettingWithdrawLimit(false);
    },

    getMasterData() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((data) => {
          const masterdata = data;
          window._.each(masterdata.settings, (item) => {
            this.siteSetting[item.key] = item.value;
          });
        });
    },
    setCountDown() {
      if (this.$refs.countDown) {
        this.$refs.countDown.setCountDown();
      }
    },
    async verifySmsOtp(otp) {
      this.isVerifying = true;
      this.isDisableButton = true;
      this.isSubmitting = true;
      this.otpSms = otp;
      let params = {
        ...this.getParams(),
      };
      await rf
        .getRequest('TransactionRequest')
        .withdraw(params)
        .then((res) => {
          this.isVerifying = false;
          if (this.securitySetting.otp_verified) {
            CommonModal.show('OtpModal');
          } else {
            this.createTransaction(this.getParams());
          }
        })
        .catch((error) => {
          this.isVerifying = false;
          if (error.response.data.errors) {
            const errors = error.response.data.errors;
            if (!errors['otp_sms']) {
              this.resetErrors();
              if (this.securitySetting.otp_verified) {
                CommonModal.show('OtpModal');
              } else {
                this.createTransaction(this.getParams());
              }
            } else {
              this.errors.add({
                field: 'otp_sms',
                msg: this.$t(errors['otp_sms'][0]),
              });
            }
          }
        });
    },
    // submitOtp(){
    //   if (this.otpSms.every((digit) => digit)) {
    //     this.$emit("submit-otp", this.otpSms.join(""));
    //   }
    //   this.verifySmsOtp()
    // }
    async handleOnComplete(value) {
      this.otpSms = await value;
      this.verifySmsOtp();
    },
    handleOnChange(value) {
      this.resetErrors();
      this.otpSms = value;
    },
    toggleEnterAddressManually() {
      if (this.enterAddressManually) {
        this.temporarySelectedAddress = this.withdrawAddress;
        this.withdrawAddress = this.temporaryInputAddress;
      } else {
        this.temporaryInputAddress = this.withdrawAddress;
        this.withdrawAddress = this.temporarySelectedAddress;
      }
      this.enterAddressManually = !this.enterAddressManually;
    },
    showModalAddressFunc() {
      this.showModalAddress = true;
    },
    closeModalAddress() {
      this.showModalAddress = false;
      this.showAddNewAddress = false;
    },
    onSelectedAddress(value) {
      this.isEmptyAddress = false;
      this.withdrawAddress = value;
      this.closeModalAddress();
    },
    showModalNewAddressFunc() {
      this.showAddNewAddress = true;
      this.showModalAddressFunc();
    },
    changeSelectedNetwork(value) {
      this.network = value;
      console.log(this.network);
    },
  },

  mounted() {
    this.maskPhoneNumberFunc();
    this.getUserKyc();
    this.getSecuritySettings();
    this.$refs.withdraw_page.$on('CHANGE_SELECTED_COIN', (selectedCoin) => {
      this.selectedCoin = selectedCoin;
      this.init();
    });
    this.$refs.withdraw_page.$on('BALANCE_UPDATED', (balance) => {
      this.balance = balance;
      this.getWithdrawDaily(this.coinName);
    });
    this.$on('UPDATED_LOCALE', () => {
      this.configs.msgEmptyData = this.$i18n.t('funds.withdrawals.no_data');
    });
  },
  created() {
    document.title = this.$t('funds.withdrawals.title_page') + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss">
.list-balance {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  .content-item-balance {
    background-color: #f8f8f8;
    padding: 16px;
    border-radius: 7px;
  }

  .item-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $text-secondary;

    @include mobile {
      font-size: 14px;
    }

    .f-left {
      min-width: 30%;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;

      @include mobile {
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
      }
    }

    .f-right {
      font-weight: 700;
      font-size: 16px;
      text-align: right;
      line-height: 24px;
      color: $text-main;
      opacity: 1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;

      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
</style>

<style lang="scss">
.otp-form {
  .modal-footer {
    padding: 0px !important;
  }
}
</style>

<style lang="scss" scoped>
.f-left,
.f-right {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $text-secondary;

  .txt-bold {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: $text-main2;
    text-transform: uppercase;
  }
}

.withdraw-content {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  border-radius: 8px;
  background-color: #f8f8f8;
  padding: 16px;

  .form-important {
    height: 80px;
    width: 100%;
    color: $color-red;
    padding: 15px 12px;
    margin-bottom: 12px;

    ul {
      padding-left: 25px;
      list-style: none;

      li {
        font-size: $font-smaller;
      }
    }
  }

  .form-group {
    width: 100%;
    margin: 0px;

    label {
      text-transform: capitalize;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $text-secondary;
    }
  }

  .quantity-box {
    display: flex;
    flex-direction: column;

    .f-left,
    .f-right {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      color: $text-secondary;

      &.withdraw-limit {
        i {
          display: none;

          @include mobile {
            display: inline-block;
          }
        }
      }

      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .txt-bold {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: $text-main2;

      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .form-address-box {
    margin-bottom: 0;

    :deep input.form-control.form-input-block-chain {
      flex: 1;

      &:disabled {
        color: $text-main !important;
        -webkit-text-fill-color: $text-main;
        opacity: 1;
        /* required on iOS */
      }
    }

    // :deep .btn-drop {
    //   margin-right: calc(60px - 41px);
    // }
  }

  .amount {
    div.amount-box.input-group {
      margin-bottom: 0;
      gap: 16px;

      @include tablet-d {
        gap: 5px 7px;
      }

      :deep .amount-input {
        width: auto;
        flex: 1;

        @include mobile {
          min-width: 150px;
        }

        .form-input-amount {
          &.error {
            border: 1px solid $color-red !important;
            background: rgba(255, 91, 91, 0.1) !important;
          }

          input {
            background: transparent;
          }
        }

        .max-amount {
          right: 16px;
        }
      }

      :deep .set-max {
        height: 42px;
        padding: 9px;
        text-align: center;
        color: white;
        font-size: 16px;
        justify-content: center;
        font-weight: 400;
        line-height: 24px;

        @include mobile {
          font-size: 14px;
        }
      }
    }
  }

  .form-address-box > label,
  :deep .form-address-box .xrp-tag > label,
  .amount > label {
    margin-bottom: 9px;

    @include mobile {
      font-size: 14px;
    }
  }
}

.form-group-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    @include btn-common(contained, md);
  }
}

.form-group-btn-address {
  padding-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 10px;

  .contained {
    @include btn-common(contained, md);
    font-size: 14px;
    font-weight: 700;
  }

  .bordered {
    @include btn-common(outlined, md);
    font-size: 14px;
    font-weight: 700;
    border-color: $bg-main1 !important;
  }
}

.form-notice {
  height: auto;
  margin-top: 16px;

  .txt-bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $text-main;
  }

  .second-ul {
    padding-left: 24px;

    li {
      list-style: '• ';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $text-secondary;
    }
  }
}

.withdrawal__footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $text-main-blur;

  @include mobile {
    font-weight: 300;
  }

  .footer__logo {
    display: flex;
    min-width: 130px;
    height: 71px;

    img {
      height: auto;
    }
  }
}

.form-notice-danger {
  padding: 16px 16px 16px 30px;
  height: auto;
  background-color: #fff9ed;
  border: 1px solid #a87f2f;
  border-radius: 8px;

  .first-ul {
    list-style: none;
    color: $color-red;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    li {
      list-style: '• ';
      color: #a87f2f;
    }
  }

  // .second-ul {
  //   padding-left: 15px;
  //   li {
  //     font-size: $font-smaller !important;
  //     line-height: 1.67;
  //     color: $color-grey-dark;
  //     margin: 0;
  //     list-style: none;
  //     &.txt-bold {
  //       margin-bottom: 15px;
  //     }
  //     &::before {
  //       content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  //       font-weight: bold; /* If you want it to be bold */
  //       display: inline-block; /* Needed to add space between the bullet and the text */
  //       width: 1em; /* Also needed for space (tweak if needed) */
  //       margin-left: -1em; /* Also needed for space (tweak if needed) */
  //     }
  //   }
  // }
}

.responsive {
  display: none;
}

.otp-box {
  position: relative;
  margin-top: 16px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 16px;

  @include mobile {
    padding: 16px 12px;
  }

  .otp-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: $text-secondary;
    margin-bottom: 16px;

    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: unset;
    }

    &_left {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: unset;
      line-height: 24px;
      color: $text-secondary;

      @include mobile {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }

    &_right {
      font-size: 14px;
      margin-bottom: unset;
      font-weight: 400;
      line-height: 24px;
      color: $text-main;

      .bold {
        font-weight: 700;
      }

      .resend-sms__btn.link__active {
        color: $color-blue;
        cursor: pointer;
      }

      @include mobile {
        font-size: 12px;
        margin-bottom: 8px;
        line-height: 16px;
      }
    }
  }

  .otp-input {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    @media (min-width: 1024px) and (max-width: 1400px) {
      flex-wrap: wrap;
    }

    @include mobile {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    #otp-input-fields {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 16px;

      @include mobile {
        justify-content: center;
        gap: 8px;
      }
    }

    .btn-otp-submit {
      background: $bg-main6;
      padding: 9px;
      border-radius: 30px;
      color: white;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      height: 42px;
      min-width: 136px;

      @include mobile {
        width: 100%;
        font-size: 14px;
      }
    }
  }

  .icon-complete {
    color: $color-green;
    border: 8px solid $color-green;
    border-radius: 50%;
    font-size: 42px;
    padding: 16px;
  }
}

.withdrawal_modal_success {
  position: relative;
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  padding: 24px;
  background: #fff;
  border-radius: 16px;

  @include mobile {
    width: calc(100dvw - 32px);
    margin: 16px auto;
  }

  .icon-close {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      padding: 24px;
      background: #f2f2f2;
      border-radius: 50%;

      @include mobile {
        width: 100px;
        height: 100px;
      }
    }

    .text_main {
      margin-top: 32px;
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      color: $text-main;
    }

    .text_sub {
      margin-top: 8px;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: $text-secondary;
    }
  }
}

.no-address {
  width: 100%;
  padding: 16px;
  border: 2px dashed #dddddd;
  border-radius: 8px;
  text-align: center;
  color: #9f9ea3;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

@include mobile {
  .responsive {
    display: block;
  }

  .form-notice-danger {
    .first-ul {
      font-size: 12px;
    }
  }

  .withdraw-content {
    gap: 15px;
    padding: 15px 17px;

    .amount {
      .f-right {
        display: none;
      }

      .f-left {
        margin-top: 5px;
        font-size: 12px;

        & > * {
          font-size: 12px;
        }
      }
    }

    .quantity-box {
      flex-direction: column;
      gap: 10px;

      .f-left,
      .f-right {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .quantity-box {
    .f-left,
    .f-right {
      justify-content: flex-start;
    }
  }
}
</style>
<style lang="scss">
.otp-input-container div:first-child {
  column-gap: 5px;
}

.otp-input-new {
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  text-align: center;
  margin: 0 5px;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input-new::-webkit-inner-spin-button,
.otp-input-new::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.enter-address {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .switch-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 4px;

    .text-enter {
      margin-bottom: 5px;
      font-weight: 300;
    }
  }
}

.address-box-new {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  cursor: pointer;
  flex-wrap: wrap;

  .wallet-name-container {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  .wallet-name {
    font-weight: 700;
    color: #23212c;
  }

  .wallet-address {
    font-weight: 500;
    color: #67666e;
    word-wrap: break-word;
    width: 100%;
  }

  .checked {
    display: none;
  }

  &.selected {
    background-color: #e7f3fc;
    border-color: #108cdd;

    .checked {
      display: block;
    }
  }
}
</style>
